import './ProjectForm.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';

const recaptchaRef = React.createRef();
const ProjectForm = () => {
    const [formData, setFormData] = useState({
      nombreLider: '',
      edad: '',
      telefono: '',
      email: '',
      participantes: ['', '', ''],
      nombreProyecto: '',
      descripcionProyecto: '',
      lugarProyecto: '',
      captcha: '', // Campo de captcha oculto
      aviso: false // Campo para aceptar aviso de privacidad
    });

    const [hasParticipants, setHasParticipants] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showLightbox, setShowLightbox] = useState(false);
    // Agregar el estado para el layer bloqueado
    const [isLocked, setIsLocked] = useState(true);
    const [countdown, setCountdown] = useState('');
    const [isRelocked, setIsRelocked] = useState(false);
    // Establecer la fecha y hora de desbloqueo
    /* const unlockDate = new Date('2024-07-08T00:00:00');  */
    const unlockDate = new Date('2024-07-12T00:00:00');
    const relockDate = new Date('2024-07-19T23:59:59');

    useEffect(() => {
      const updateCountdown = () => {
        const now = new Date();
        const distanceToUnlock = unlockDate - now;
        const distanceToRelock = relockDate - now;

        if (distanceToUnlock <= 0 && distanceToRelock > 0) {
          setIsLocked(false);
          setIsRelocked(false);
        } else if (distanceToRelock <= 0) {
          setIsLocked(true);
          setIsRelocked(true);
          setCountdown(''); // Limpiar la cuenta regresiva después de rebloquear
        } else {
          const targetDate = distanceToUnlock > 0 ? unlockDate : relockDate;
          const distance = targetDate - now;

          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      };

      // Actualizar la cuenta regresiva inmediatamente
      updateCountdown();

      // Configurar un intervalo para actualizar cada segundo
      const intervalId = setInterval(updateCountdown, 1000);

      return () => clearInterval(intervalId);
    }, []);

    const handleChange = (e) => {
      const {
        name,
        value,
        type,
        checked
      } = e.target;
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value
      });
    };

    const handleParticipantChange = (index, value) => {
      const newParticipants = [...formData.participantes];
      newParticipants[index] = value;
      setFormData({
        ...formData,
        participantes: newParticipants
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault()
      try {
        setIsSubmitting(true);
        const token = await recaptchaRef.current.executeAsync();
        formData['token'] = token;
        axios.post('http://localhost:3002/register', formData)
          .then(response => {
            setIsSubmitting(false);
            setResponseMessage(response.data.message);
            setShowLightbox(true);
            // Restablecer el formulario si es necesario
            setFormData({
              token,
              nombreLider: '',
              edad: '',
              telefono: '',
              email: '',
              participantes: ['', '', ''],
              nombreProyecto: '',
              descripcionProyecto: '',
              lugarProyecto: '',
              captcha: '', // Limpiar el campo de captcha después del envío
              privacidad: false // Desmarcar la casilla de aviso de privacidad
            });
            setHasParticipants(false);
          })
          .catch(error => {
            console.error('Hubo un error registrando el formulario', error);
            setIsSubmitting(false);
          });
      } catch (e) {

      }

    };
    const isFormValid = () => {
      const {
        nombreLider,
        edad,
        telefono,
        email,
        nombreProyecto,
        descripcionProyecto,
        lugarProyecto,
        aviso
      } = formData;
      return nombreLider && edad && telefono && email && nombreProyecto && descripcionProyecto && lugarProyecto && aviso;
    };

    const morelosLocations = [
      "Amacuzac",
      "Atlatlahucan",
      "Axochiapan",
      "Ayala",
      "Coatlán del Río",
      "Cuautla",
      "Cuernavaca",
      "Emiliano Zapata",
      "Huitzilac",
      "Jantetelco	",
      "Jiutepec",
      "Jojutla",
      "Jonacatepec de Leandro Valle",
      "Mazatepec",
      "Miacatlán",
      "Ocuituco",
      "Puente de Ixtla",
      "Temixco",
      "Tepalcingo",
      "Tepoztlán",
      "Tetecala",
      "Tetela del Volcán",
      "Tlalnepantla",
      "Tlaltizapán de Zapata",
      "Tlaquiltenango",
      "Tlayacapan",
      "Totolapan",
      "Xochitepec",
      "Yautepec",
      "Yecapixtla",
      "Zacatepec",
      "Zacualpan de Amilpas",
      "Temoac",
      "Coatetelco",
      "Xoxocotla",
      "Hueyapan",
    ];

    return (

      <
      > {
        isLocked && ( <
          div className = "locked-layer" > {
            isRelocked ? ( <
              p > El tiempo de registro termino. < /p>
            ) : ( <
              p > El formulario estará disponible en {
                countdown
              } < /p>
            )
          } <
          /div>
        )
      } {
        !isLocked && ( <
          >
          <
          header >
          <
          nav >
          <
          div class = "menu" > {
            /* <img src="img/menu.svg" alt="" onclick="mostrar()" /> */ } <
          /div>

          <
          div class = "logo-fm" >
          <
          img src = "img/fm.svg"
          alt = "" / >
          <
          /div> <
          /nav> <
          /header> <
          main >
          <
          div class = "logo-kicks" >
          <
          img src = "img/exa-kicks.svg"
          alt = "" / >
          <
          /div> <
          div class = "cuadro1" >
          <
          img src = "img/cuadros.svg"
          alt = "" / >
          <
          /div> <
          div class = "cuadro2" >
          <
          img src = "img/cuadros.svg"
          alt = "" / >
          <
          /div>

          <
          div class = "formulario" >
          <
          form onSubmit = {
            handleSubmit
          } > {
            /* Campo de captcha oculto */ } <
          ReCAPTCHA ref = {
            recaptchaRef
          }
          size = "invisible"
          sitekey = "6Lc56AcqAAAAAKhCLQ6MA9nxOBTSUCbbq_BaZHon"

          /
          >

          <
          div class = "form-group" >
          <
          label
          for = "nombreLider" > Nombre del líder del proyecto < /label> <
          input type = "text"
          name = "nombreLider"
          value = {
            formData.nombreLider
          }
          onChange = {
            handleChange
          }
          required / >
          <
          /div> <
          div class = "form-group" >
          <
          label > Correo electrónico: < /label> <
          input type = "email"
          name = "email"
          value = {
            formData.email
          }
          onChange = {
            handleChange
          }
          required / >
          <
          /div> <
          div class = "form-group-col" >
          <
          div class = "col-2 col-tel" >
          <
          label > Edad < /label> <
          input type = "number"
          name = "edad"
          value = {
            formData.edad
          }
          onChange = {
            handleChange
          }
          required / >
          <
          /div> <
          div class = "col-2" >
          <
          label > Teléfono Móvil < /label> <
          input type = "tel"
          name = "telefono"
          value = {
            formData.telefono
          }
          onChange = {
            handleChange
          }
          required / >
          <
          /div> <
          /div> <
          div class = "form-group-radio" >
          <
          label > ¿Hay más participantes ? < /label> <
          div class = "group-radio" >
          <
          input type = "radio"
          id = "hasParticipantsYes"
          name = "hasParticipants"
          value = "yes"
          checked = {
            hasParticipants === true
          }
          onChange = {
            () => setHasParticipants(true)
          }
          /> <
          label htmlFor = "hasParticipantsYes" > Sí < /label> <
          /div> <
          div class = "group-radio" >
          <
          input type = "radio"
          id = "hasParticipantsNo"
          name = "hasParticipants"
          value = "no"
          checked = {
            hasParticipants === false
          }
          onChange = {
            () => setHasParticipants(false)
          }
          /> <
          label htmlFor = "hasParticipantsNo" > No < /label> <
          /div> <
          /div> {
            hasParticipants && ( <
              div class = "form-group" >
              <
              label > Más participantes: < /label> {
                formData.participantes.map((participant, index) => ( <
                  input key = {
                    index
                  }
                  class = "special"
                  type = "text"
                  value = {
                    participant
                  }
                  onChange = {
                    (e) => handleParticipantChange(index, e.target.value)
                  }
                  placeholder = {
                    `Nombre participante ${index + 1}`
                  }
                  />
                ))
              } <
              /div>
            )
          } <
          div class = "form-group" >
          <
          label > Nombre del proyecto : < /label> <
          input type = "text"
          name = "nombreProyecto"
          value = {
            formData.nombreProyecto
          }
          onChange = {
            handleChange
          }
          required / >
          <
          /div> <
          div class = "form-group" >
          <
          label > Descripción detallada del proyecto: < /label> <
          textarea name = "descripcionProyecto"
          value = {
            formData.descripcionProyecto
          }
          onChange = {
            handleChange
          }
          required /
          >
          <
          /div> <
          div class = "form-group" >
          <
          label > Lugar en donde se desarrollará el proyecto: < /label> <
          select name = "lugarProyecto"
          value = {
            formData.lugarProyecto
          }
          onChange = {
            handleChange
          }
          required >
          <
          option value = ""
          disabled > Selecciona un lugar < /option> {
            morelosLocations.map((location, index) => ( <
              option key = {
                index
              }
              value = {
                location
              } > {
                location
              } < /option>
            ))
          } <
          /select> <
          /div> <
          div class = "form-group-check" >
          <
          input type = "checkbox"
          name = "aviso"
          checked = {
            formData.aviso
          }
          onChange = {
            handleChange
          }
          required /
          >
          <
          label
          for = "aceptoTC" > Acepto < a href = "https://mvswp.blob.core.windows.net/mvsads/avisos/TyC_Nissan_Kicks.pdf"
          target = "_blank" > Aviso de privacidad y términos y condiciones< /a></label >
          <
          /div> <
          div class = "btn" >
          <
          button type = "submit"
          disabled = {
            !isFormValid() || isSubmitting
          } >
          Registrar Proyecto {
            isSubmitting && < span className = "loader" > < /span>} <
              /button> <
              /div> <
              div class = "logos-nissan" >
              <
              img src = "img/logos-nissan.webp"
            alt = "" / >
              <
              /div> <
              /form> <
              /div> <
              /main> <
              footer >
              <
              p > EXA Radio® Todos los derechos reservados. < /p> <
              /footer> <
              />
          )
        }

        {
          showLightbox && ( <
            div className = "lightbox-background" >
            <
            div className = "lightbox-content" >
            <
            div class = "gracias" >
            <
            p > Gracias por el registro de tu <
            span > proyecto. < /span> </p >
            <
            /div> <
            p > {
              responseMessage
            } < /p> <
            button onClick = {
              () => setShowLightbox(false)
            } > Cerrar < /button> <
            /div> <
            /div>
          )
        } <
        />
      );
    };

    export default ProjectForm;