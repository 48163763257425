import './App.css';

import ProjectForm from './ProjectForm';

function App() {
  return (
    <div className="App">
      <ProjectForm/>
    </div>
  );
}

export default App;
